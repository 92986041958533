// eye
<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <v-container>
        <v-row>
          <v-col md="3" class="mr-2" v-if="returnAbility('responsibility:store')">
            <template>
              <v-btn @click="showDialog" color="green" class="white--text">
                <v-icon size="20">mdi-plus</v-icon>
                {{ $t("add weekly lesson") }}
              </v-btn>
            </template>
            <v-dialog v-model="dialog" width="500">
              <v-card>
                <v-card-title class="d-flex justify-center card-title__heading">
                  {{ $t("add weekly lesson") }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col md="6">
                      <v-autocomplete
                        v-model="newWeeklyLesson.teacher_id"
                        outlined
                        dense
                        :items="teachers"
                        :label="$t('teacher')"
                        item-text="name"
                        item-value="id"
                        :search-input.sync="nameSearch2"
                        :loading="getLoadingStatus"
                      ></v-autocomplete>
                    </v-col>
                    <v-col md="6">
                      <v-autocomplete
                        v-model="newWeeklyLesson.edu_class_id"
                        outlined
                        dense
                        :items="eduClasses"
                        :label="$t('educitional class')"
                        item-text="name"
                        item-value="id"
                        :search-input.sync="eduSearch2"
                        :loading="getLoading"
                        @change="updateSelectedValue()"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col md="6">
                      <label
                        for="autocomplete"
                        :style="'font-size: 14px;color:green'"
                        >{{ $t("Please select education class first") }}</label
                      >
                      <v-autocomplete
                        v-model="newWeeklyLesson.subject_id"
                        outlined
                        dense
                        d="autocomplete"
                        loading-color="primary"
                        :loading="getLoadingSubjectsStatus"
                        :search-input.sync="subjectSearch2"
                        :items="subjects"
                        :label="$t('subject')"
                        item-text="name"
                        item-value="id"
                        :disabled="disableSubjectBtn"
                      ></v-autocomplete>
                    </v-col>
                    <v-col md="6">
                      <label for="autocomplete" :style="'color: white;'"
                        >day</label
                      >
                      <v-autocomplete
                        v-model="newWeeklyLesson.day"
                        outlined
                        dense
                        :items="days"
                        :label="$t('day')"
                      ></v-autocomplete
                    ></v-col>
                    <v-col md="6">
                      <v-autocomplete
                        :label="$t('order')"
                        v-model="newWeeklyLesson.order"
                        outlined
                        dense
                        color="#757575"
                        type="text"
                        :items="[1, 2, 3, 4, 5, 6, 7]"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="green"
                    class="white--text"
                    @click="submitnewWeeklyLesson"
                    :loading="submitBtnLoading"
                    :disabled="disableSubmitBtn"
                    >{{ $t("add") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col md="2" class="mr-2"  v-if="returnAbility('responsibility:export')">
                <ExportExcel end-point="/responsibility/export" />
          </v-col>
          <v-col md="2" class="mr-2"  v-if="returnAbility('responsibility:import')">
              <ImportExcel end-point="/responsibility/import" params="responsibilty" @fetchData="fetchData"/>
          </v-col>
          </v-row>
          <v-row>
          <v-col md="2.5">
            <v-autocomplete
              v-model="teacher_id"
              outlined
              dense
              :items="teachers"
              :label="$t('search by name')"
              :search-input.sync="nameSearch"
              :loading="getLoadingStatus"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
            <!-- <v-autocomplete
              v-model="data.meal_id"
              :items="mealItems"
              item-text="name"
              item-value="id"
              :search-input.sync="mealSearch"
              :loading="meal.getMealsNameState.loading"
              :label="$t('meal name')"
              :hint="$t('requird')"
            ></v-autocomplete> -->
          </v-col>
          <v-col md="2.5">
            <v-autocomplete
              v-model="edu_class_id"
              outlined
              dense
              :items="eduClasses"
              :label="$t('search by educitional class')"
              item-text="name"
              item-value="id"
              :search-input.sync="eduSearch"
              :loading="getLoading"
            ></v-autocomplete>
          </v-col>
          <v-col md="2.5">
            <v-autocomplete
              v-model="subject_id"
              outlined
              dense
              :items="subjects"
              :label="$t('search by subject')"
              item-text="name"
              item-value="id"
              :search-input.sync="subjectSearch"
              :loading="getLoadingSubjectsStatus"
            ></v-autocomplete>
          </v-col>
          <v-col md="2.5">
            <v-autocomplete
              v-model="order"
              :label="$t('search by order')"
              outlined
              dense
              color="#757575"
              type="text"
              :items="[1, 2, 3, 4, 5, 6, 7]"
            ></v-autocomplete>
          </v-col>
          <v-col md="2.5" class="d-flex justify-center align-center">
            <v-autocomplete
              v-model="day"
              outlined
              dense
              :items="days"
              :label="$t('search by day')"
            ></v-autocomplete>
            <v-icon class="mr-2 mb-5" @click="fetchData(1)">mdi-magnify</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <app-base-table
        :tableOptions="tableOptions"
        @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)"
        :serverRoute="'/responsibility'"
        :enableDelete="displayDeleteIcon"
        :flaggedItems="flaggedItemsData"
      >
        <template slot="editFeature" slot-scope="{ row }">
          <v-icon
            color="green"
            @click="$router.push(`/view-responsibility/${row.id}`)"
            >mdi-eye</v-icon
          >
          <app-edit-responsibility-modal
            @recordUpdated="fetchData"
            :recordData="row"
          ></app-edit-responsibility-modal>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import editResponsibilityModal from "./editResponsibilityModal.vue";
import ExportExcel from "../../components/core/ExportExcel.vue";
import ImportExcel from "../../components/core/ImportExcel.vue";
import axios from "axios";

export default {
  components: {
    appEditResponsibilityModal: editResponsibilityModal,
    ExportExcel,
    ImportExcel,
  },
  data() {
    return {
      eduSearch: null,
      subjectSearch: null,
      nameSearch: null,
      eduSearch2: null,
      subjectSearch2: null,
      nameSearch2: null,
      grade_type: "",
      dialog: false,
      submitBtnLoading: false,
      edu_class_id: "",
      teacher_id: "",
      subject_id: "",
      day: "",
      order: "",
      isLoading: false,
      flaggedItemsData: [
        "created_at",
        "email_verified_at",
        "image",
        "token",
        "type",
        "updated_at",
        "name",
        // "joining_date",
      ],
      newWeeklyLesson: {
        teacher_id: "",
        subject_id: "",
        edu_class_id: "",
        order: "",
        day: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "responsibilities/getTableOptions",
      teachers: "teachers/getTeachers",
      subjects: "subjects/getSubjects",
      eduClasses: "educationClass/getEduClasses",
      getLoading: "educationClass/getLoading",
      days: "teachers/getDays",
      getLoadingStatus: "teachers/getLoadingStatus",
      getLoadingSubjectsStatus: "subjects/getIsLoadingStatus",
    }),
    disableSubjectBtn() {
      if (!this.newWeeklyLesson.edu_class_id) {
        return true;
      } else {
        return false;
      }
    },
    disableSubmitBtn() {
      if (
        !this.newWeeklyLesson.teacher_id ||
        !this.newWeeklyLesson.subject_id ||
        !this.newWeeklyLesson.edu_class_id ||
        !this.newWeeklyLesson.day ||
        !this.newWeeklyLesson.order
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations({
      setTeachers: "teachers/setTeachers",
      setSubjects: "subjects/setSubjects",
      setEduClasses: "educationClass/setEduClasses",
    }),
    ...mapActions({
      fetchResponsibilities: "responsibilities/fetchResponsibilities",
      fetchTeachers: "teachers/fetchTeachers",
      fetchSubjects: "subjects/fetchSubjects",
      // isLoadingSubjects: "subjects/getIsLoadingStatus",
      fetchAllEduClasses: "educationClass/fetchEducationClass",
    }),
    // fetchSub() {
    //   console.log("nn", this.grade_type);
    // },
    showDialog() {
      this.setTeachers([]);
      this.setSubjects([]);
      this.setEduClasses([]);
      this.dialog = true;
    },
    updateSelectedValue() {
      this.eduClasses.find((item) => {
        if (item.id == this.newWeeklyLesson.edu_class_id) {
          this.grade_type = item.grade.id;
        
        }
      });
      if (this.newWeeklyLesson.edu_class_id !== "") {
        try {
          this.isLoading = true;
          this.fetchSubjects({
            grade_type_id: this.grade_type,
          });
        } catch (err) {
        } finally {
          this.isLoading = false;
        }
      }
    },
    fetchData(page) {
      this.dialog = false;
      // this.fetchTeachers({
      //   params: {
      //     page,
      //   },
      // });
      this.fetchResponsibilities({
        params: {
          page,
          edu_class_id: this.edu_class_id,
          teacher_id: this.teacher_id,
          subject_id: this.subject_id,
          day: this.day,
          order: this.order,
        },
      });
    },
    async submitnewWeeklyLesson() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post(
          "/responsibility",
          this.newWeeklyLesson
        );
        this.$Notifications(
          this.$t("add success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
        this.dialog = false;
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitBtnLoading = false;
      }
    },
  },
  watch: {
    nameSearch(val) {
      
      if ((val != "") & (val != null)) {
        this.fetchTeachers({
          params: {
            name: val,
          },
        });
      } else {
        this.teachers = [];
      }
    },
    subjectSearch(val) {
      
      if ((val != "") & (val != null)) {
        this.fetchSubjects({
          name: val,
        });
      } else {
        this.subjects = [];
      }
    },
    eduSearch(val) {
      
      if ((val != "") & (val != null)) {
        this.fetchAllEduClasses({
          params: {
            name: val,
          },
        });
      } else {
        this.eduClasses = [];
      }
    },
    nameSearch2(val) {
      
      if ((val != "") & (val != null)) {
        this.fetchTeachers({
          params: {
            name: val,
          },
        });
      } else {
        this.teachers = [];
      }
    },
    subjectSearch2(val) {
      
      if ((val != "") & (val != null)) {
        this.fetchSubjects({
          name: val,
        });
      } else {
        this.subjects = [];
      }
    },
    eduSearch2(val) {
  
      if ((val != "") & (val != null)) {
        this.fetchAllEduClasses({
          params: {
            name: val,
          },
        });
      } else {
        this.eduClasses = [];
      }
    },
  },

  created() {
    this.setTeachers([]);
    this.setSubjects([]);
    this.setEduClasses([]);
  
    if (this.returnAbility("responsibility:delete")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);

    // this.fetchAllEduClasses();
  },
};
</script>

<style></style>
