<template>
  <v-dialog v-model="editDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t("edit") }}</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6">
            <v-autocomplete
              v-model="editResponsibility.teacher_id"
              outlined
              dense
              :items="teachers"
              :label="$t('teacher')"
              :search-input.sync="nameSearch"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col md="6">
            <v-autocomplete
              v-model="editResponsibility.edu_class_id"
              outlined
              dense
              :items="eduClasses"
              :label="$t('educitional class')"
              :search-input.sync="eduSearch"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col md="6">
            <v-autocomplete
              v-model="editResponsibility.subject_id"
              outlined
              dense
              :items="subjects"
              :label="$t('subject')"
              :search-input.sync="subjectSearch"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col md="6">
            <v-autocomplete
              v-model="editResponsibility.day"
              outlined
              dense
              :items="days"
              :label="$t('day')"
            ></v-autocomplete
          ></v-col>
          <v-col md="6">
            <v-autocomplete
              :label="$t('order')"
              v-model="editResponsibility.order"
              outlined
              dense
              color="#757575"
              type="text"
              :items="[1, 2, 3, 4, 5, 6]"
            ></v-autocomplete
          ></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="green"
          class="white--text"
          depressed
          @click="editRecord"
          :loading="editBtnLoading"
          >{{ $t("edit") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      eduSearch: null,
      subjectSearch: null,
      nameSearch: null,
      editResponsibility: {
        order: "",
        day: "",
        teacher_id: "",
        subject_id: "",
        edu_class_id: "",
      },
      editBtnLoading: false,
      editDialog: false,
    };
  },
  props: {
    recordData: Object,
  },
  watch: {
    nameSearch(val) {
      
      if ((val != "") & (val != null)) {
        this.fetchTeachers({
          params: {
            name: val,
          },
        });
      } else {
        this.teachers = [];
      }
    },
    subjectSearch(val) {
      
      if ((val != "") & (val != null)) {
        this.fetchSubjects({
          name: val,
        });
      } else {
        this.subjects = [];
      }
    },
    eduSearch(val) {
      
      if ((val != "") & (val != null)) {
        this.fetchAllEduClasses({
          params: {
            name: val,
          },
        });
      } else {
        this.eduClasses = [];
      }
    },
    editDialog() {
      this.editResponsibility.order = this.recordData.order;
      this.editResponsibility.day = this.recordData.day;
      this.editResponsibility.teacher_id = this.recordData.teacher.id;
      this.editResponsibility.subject_id = this.recordData.subject.id;
      this.editResponsibility.edu_class_id = this.recordData.edu_class.id;
      // this.editResponsibility.password = this.recordData.password;
    },
  },
  methods: {
    ...mapActions({
      fetchTeachers: "teachers/fetchTeachers",
      fetchSubjects: "subjects/fetchSubjects",
      fetchAllEduClasses: "educationClass/fetchAllEduClasses",
    }),
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(
          `/responsibility/${this.recordData.id}`,
          this.editResponsibility
        );
        this.$Notifications(
          this.$t("edit success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
        console.log("re", err);
      } finally {
        this.editBtnLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      teachers: "teachers/getTeachers",
      subjects: "subjects/getSubjects",
      eduClasses: "educationClass/getEduClasses",
      days: "teachers/getDays",
    }),
  },
  created() {},
};
</script>

<style></style>
